@import url(theme.css);

.form-label {
    font-weight: 500;
    font-size: 14px !important;
    margin-bottom: 3px !important;
}

.help-block, .invalid-feedback, .valid-feedback {
    font-size: .6875rem;
    margin-top: .325rem;
}

.help-block {
    color: #909090;
}

.inline .form-label {
    width: 50% !important;
}

.inline .form-control-custom, .inline .react-datepicker-wrapper, .inline .react-datepicker__input-container {
    width: 50% !important;
    display: unset !important;
}

.inline .custom-checkbox {
    width: 50%;
    display: inherit;
}

.inline .adpAcpCheckboxContainer .custom-checkbox {
    width: unset !important;
}


.inline .form-control-custom.no-border {
    padding: 0;
}

.inline-text-box {
    display: inline-block;
    width: 100%;
    padding: .3rem .5rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid silver;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control-custom, .form-control {
    display: block;
    width: 100%;
    /*height: calc(1.47em + 1rem + 2px);*/
    padding: .3rem .5rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid silver;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

    .form-control-custom:focus, .form-control:focus {
        color: #000;
        background-color: #fff;
        border-color: var(--dark-blue);
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
    }

.search-inline-button {
    top: 10px;
    right: 15px;
}

.inline-button, .search-inline-button {
    position: absolute;
    transition: right .2s;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--dark-blue);
    background-color: var(--dark-blue);
}

.react-datepicker-wrapper {
    display: block !important;
}

.form-group {
    margin-bottom: .5rem;
}

.no-border {
    border: 0 !important;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.19437rem;
    padding-left: 1.625rem;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    z-index: 1;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: var(--dark-blue) !important;
    border-color: var(--dark-blue) !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--dark-blue) !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(111, 178, 70, .25) !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background-color: lightgrey;
    color: grey !important;
}

.custom-file-label {
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
}

.input-icons button {
    position: absolute;
    right: 15px;
    bottom: 10px;
    z-index: 3;
    display: block;
    height: calc(1.47em + 1rem);
    padding: .5rem .875rem;
    line-height: 1.47;
    color: #495057;
    background-color: #f3f3f3;
    border-left: inherit;
    border-radius: 0 4px 4px 0;
    border: 1px solid #ced4da;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.pr-4rem {
    padding-right: 4rem;
}

.custom-control-inline {
    display: inline-flex;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: var(--dark-blue);
}

.form-group.d-flex > div {
    width: unset !important;
    line-height: 1.5;
}

.slide-pane__content {
    padding: 0px 32px !important;
}

li, p {
    line-height: 1.5;
}