
.page-content-wrapper {
    background-color: #fff;
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    padding: 0;
    flex-basis: 100%;
    flex-direction: column;
    width: 0;
    min-width: 0;
    max-width: 100%;
    min-height: 1px;
}

.page-inner, .page-wrapper {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.page-inner {
    min-height: 100vh;
}

.page-content {
    flex: 1 1 auto;
    order: 3;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 3rem !important;
}

@media screen and (min-width: 992px) {
    .nav-function-top .page-wrapper {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 992px) {
    .nav-function-top .page-wrapper {
        padding-left: 0;
    }

    .page-content {
        padding: 1.5rem 2rem;
    }
}

@media screen and (max-width: 991px) {
    .page-content {
        padding: 0 1rem;
    }
}

.page-inner, .page-wrapper {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.page-wrapper {
    position: relative;
}

.search-menu {
    position: absolute;
    transform: translate3d(0px, 55px, 0px) !important;
    top: 10px;
    right: 0px !important;
    left: unset !important;
    will-change: transform;
    min-width: 500px;
    width: 35vw;
}

.inline-button {
    position: absolute;
    top: 8px;
    right: 5px;
    transition: right 0.2s;
    width: 50px;
    color: var(--dark-text);
}

.ant-modal-wrap {
    z-index: 100000;
}

.hover:hover {
    cursor: pointer;
}

.current-user {
    color: yellow !important;
    font-weight: bold;
    font-size: 14px;
    padding: 1.50rem 2rem 0.8125rem 2rem !important;
}

.blue-label {
    color: #AA1429 !important;
    font-weight: bold;
    font-size: 14px;
}

.local777-reporting-card .ant-card-head {
    background: #255398;
    color: #fff;
    text-align: center;
    border-bottom: 6px solid transparent;
    border-image: linear-gradient(45deg, #36393D, #848B96, #36393D);
    border-image-slice: 1;
}

.local777-reporting-card {
    border-bottom: 6px solid transparent;
    /*border-image: linear-gradient(45deg, #36393D, #848B96, #36393D);
    border-image-slice: 1;*/
    border-radius: 4px;
    height: 100%;
    border-color: #36393D;
}

.highlight {
    background: rgba(111, 178, 70, .5);
}