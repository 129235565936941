.ant-layout-header {
    padding: 0;
    padding-inline: 0 !important;
}

.ant-layout {
    background: unset !important;
}

.ant-layout-content {
    overflow: auto !important;
}

/*NAV*/
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
    border-bottom: unset !important;
}

.ant-menu-item:hover {
    background: rgba(0,0,0,.1) !important;
}
/*CARDS*/
.card-container p {
    margin: 0;
}

.card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

    .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        padding: 16px;
        background: #fff;
    }

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
}

[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}

[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}

[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
}

[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
}

.ant-tag {
    margin: 0 8px 3px 0 !important;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
    height: 55px;
    cursor: pointer;
}

.ant-upload-list-item {
    height: 45px !important;
}

.ant-upload.ant-upload-drag {
    height: unset !important;
}

.ant-switch {
    width: 44px;
}

.ant-btn-warning {
    border-color: #ffc107 !important;
    background: #ffc107 !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
}

    .ant-btn-warning:focus, .ant-btn-warning:hover {
        color: #000 !important;
        border-color: #ffca2c !important;
        background: #ffca2c !important;
    }

.ant-table-row:hover {
    cursor: pointer !important;
}

.ant-form-item-explain-success {
    color: rgb(25,135,84) !important;
}

.row-selected {
    background: #ececec;
}

.ignore-height .ant-empty-image {
    height: unset !important;
}

.ant-picker-dropdown {
    z-index: 11111 !important;
}

.ant-modal {
    z-index: 99999 !important;
}

.ant-modal-wrap {
    z-index: 111111 !important;
}

.custom-ant-selection .ant-table-selection-column {
    padding: 0 !important;
    margin: 0 !important;
}

.custom-ant-selection .ant-table-selection > .ant-checkbox-wrapper {
    display: none;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background: #C5DBEC !important;
    /*color: orange;*/
}

.ant-table-expanded-row .ant-table {
    line-height: .5;
}

/*.ant-table-expanded-row th.ant-table-cell {
    color: rgb(250,250,250);
    background: rgba(0, 0, 0, 0.85);
}
.ant-table-expanded-row td.ant-table-cell {
    background: lightgrey;
}*/

.ant-table-expanded-row th {
    border-bottom: 1px solid rgb(14,66,141) !important;
}

.ant-table-expanded-row .ant-table {
    border: 1px solid rgb(14,66,141) !important;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.ant-popover {
    z-index: 11111 !important;
}