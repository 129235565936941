
.figure {
    font-size: 2.2rem;
    line-height: 2rem;
    font-weight: 700;
    padding-top: 1rem;
}

.count-type {
    font-size: 1.05rem;
    line-height: .9rem;
    text-align: right;
}

.left-card-content {
    display: inline-block;
    padding: .5rem 2.2rem;
    font-weight: 300;
    vertical-align: middle;
    border-bottom: none;
    text-align: right;
    width: 49%;
    height: 100%;
}

.card {
    margin: 10px 0;
}

.card-body {
    padding: 5px 7px;
}

.list-group-flush .list-group-item {
    border-top: .5px solid grey;
}

.local777Card {
}

    .local777Card .editIcon {
        color: rgba(24, 110, 178, 1);
    }

        .local777Card .editIcon:hover {
            color: deeppink;
        }

    .local777Card .card-header, .local777Card .modal-header, .card-column-header {
        background-color: rgba(24, 110, 178, .25);
        color: rgba(24, 110, 178, 1);
        padding: .45rem .5rem .35rem;
        letter-spacing: .05rem;
        font-size: 1.05rem;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        border-bottom: solid 3px rgba(24, 110, 178, 1);
    }

    .local777Card .dark-header {
        background-color: rgba(24, 110, 178, 1) !important;
        color: #fff !important;
    }

    .local777Card .dark-body {
        background-color: rgba(24, 110, 178, .25) !important;
    }

    .local777Card .card-body {
        border-top: 0;
        border-radius: 0 0 4px 4px;
    }

    .local777Card .custom-card-label {
        font-weight: 700;
    }

.modal-header {
    padding: 20px !important;
}

.local777Card .slide-pane__header {
    background-color: #ededed;
    color: rgba(243, 0, 121, 1);
    padding: .45rem .5rem .35rem;
    letter-spacing: .05rem;
    font-size: 1.05rem;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    border-bottom: solid 3px rgba(243, 0, 121, 1);
}

.slide-pane .slide-pane__title {
    font-weight: 700 !important;
}